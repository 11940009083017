import React from "react"
import Image from "gatsby-image"
import styles from "../../css/tour.module.css"
import { FaMapMarkerAlt } from "react-icons/fa"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"

// Pulling default image from Contentful
const getImage = graphql`
  query {
    file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Project = ({ project }) => {
  const data = useStaticQuery(getImage)
  const img = data.file.childImageSharp.fluid
  const { name, location, slug, images, services, category, coverImg } = project

  // Check if images array is empty. If it is, use default
  let mainImage = coverImg ? coverImg.fluid : img

  return (
    <Fade bottom fraction=".01">
      <article className={styles.project}>
        <div className={styles.imgContainer}>
          <Image
            fluid={mainImage}
            className={styles.img}
            alt="Single Project"
          />
          <AniLink fade className={styles.link} to={`/projects/${slug}`}>
            Detalles
          </AniLink>
        </div>
        <div className={styles.footer}>
          <h1>{name}</h1>
          <div className={styles.info}>
            <h4 className={styles.country}>
              <FaMapMarkerAlt className={styles.icon} />
              {location || "Edomex, MX"}
            </h4>
            <div className={styles.details}>
              <h6>{category || "Arquitectura"}</h6>
            </div>
          </div>
        </div>
      </article>
    </Fade>
  )
}

Project.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default Project
