import React from "react"
import ProjectList from "./ProjectList"
import { useStaticQuery, graphql } from "gatsby"

const getProjects = graphql`
  query {
    projects: allContentfulProjects {
      edges {
        node {
          name
          slug
          location
          category
          services
          coverImg {
            fluid(quality: 40) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
// ...GatsbyContentfulFluid_tracedSVG

const Projects = () => {
  const { projects } = useStaticQuery(getProjects)
  return <ProjectList projects={projects} />
}
export default Projects
